define("ember-pouch/index", ["exports", "ember-pouch/model", "ember-pouch/adapters/pouch", "ember-pouch/serializers/pouch"], function (_exports, _model, _pouch, _pouch2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function get() {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "Adapter", {
    enumerable: true,
    get: function get() {
      return _pouch.default;
    }
  });
  Object.defineProperty(_exports, "Serializer", {
    enumerable: true,
    get: function get() {
      return _pouch2.default;
    }
  });
});